<template>
  <div id="layout-wrapper">
    <NavBar/>
    <div class="container-fluid p-0 m-0">
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="40"/>
              </span>
            </router-link>

          </div>
          <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div class="container-fluid">
            <ul class="navbar-nav" id="navbar-nav">
              <li class="menu-title"><span data-key="t-menu">Menu</span></li>
              <li class="nav-item">
                <RouterLink class="nav-link menu-link" :class="this.selectedPath === '/admin/dashboard' ? 'active': ''" to="/admin/dashboard">
                  <i class="ri-dashboard-3-line"></i> <span data-key="t-widgets">{{$t('dashboard')}}</span>
                </RouterLink>
                <RouterLink class="nav-link menu-link" :class="this.selectedPath === '/admin/users' || this.selectedPath === '/admin/userdetail'  ? 'active': ''" to="/admin/users">
                  <i class="ri-user-3-line"></i> <span data-key="t-widgets">{{$t("manage_users")}}</span>
                </RouterLink>
              </li>
            </ul>
          </div>
          <!-- Sidebar -->
        </div>
        <div class="sidebar-background"></div>
      </div>

      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid pb-5">
            <router-view></router-view>
          </div>
        </div>

      </div>

    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from "@/components/nav-bar";
import Footer from "@/components/footer";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    NavBar,
    Footer
  },
  methods: {
    ...mapActions("admin", ["selectPath"])
  },
  computed: {
    ...mapGetters("admin", ["selectedPath"])
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "vertical");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "default");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
    document.querySelector("#user-panel-logo").style.visibility = "hidden";
  },
  beforeMount() {
    this.selectPath(router.currentRoute.value.path);
  },
  watch: {
    $route(to){
      this.selectPath(to.path);
    }
  }
}
</script>

<style scoped lang="scss">
.active{
  color: red!important;
}
</style>